@charset "UTF-8";
@tailwind base;
html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11", "salt", "calt", "case";
  @apply font-sans leading-normal antialiased;
}

body {
  height: 100%;
  letter-spacing: -0.006em;
  @apply text-sm text-black;
}

div {
  box-sizing: border-box;
}

input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  border-left: 3px solid theme("backgroundColor.primary");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;
}
blockquote > * {
  margin: 0;
}

.btn-primary-accent {
  position: relative;
  display: inline-block;
  min-width: 200px;
  color: rgb(255, 255, 255);
  background-color: var(--color-accent-rgb);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.vex-toolbar {
  box-shadow: none !important;
}

.secondary-toolbar,
vex-breadcrumbs {
  /*box-shadow: none!important;
  --tw-shadow: none!important;*/
}
.secondary-toolbar i.icon,
vex-breadcrumbs i.icon {
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 480px) {
  .secondary-toolbar i.icon,
  vex-breadcrumbs i.icon {
    width: 38px;
    height: 38px;
  }
}
.secondary-toolbar i.icon img,
vex-breadcrumbs i.icon img {
  width: 36px;
  height: 36px;
  margin-top: -4px;
}
@media (max-width: 480px) {
  .secondary-toolbar i.icon img,
  vex-breadcrumbs i.icon img {
    margin-top: -2px;
    width: 28px;
    height: 28px;
  }
}
.secondary-toolbar .title,
vex-breadcrumbs .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: var(--color-dark1);
  width: auto !important;
  float: none !important;
  /*&:before {
    content: "\2022";
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    margin-right: 8px;
  }*/
}
@media (max-width: 480px) {
  .secondary-toolbar .title,
  vex-breadcrumbs .title {
    font-size: 16px;
  }
}
.secondary-toolbar .subtitle,
vex-breadcrumbs .subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--color-dark1);
}
@media (max-width: 480px) {
  .secondary-toolbar .subtitle,
  vex-breadcrumbs .subtitle {
    font-size: 12px;
  }
}
.secondary-toolbar .subtitle:before,
vex-breadcrumbs .subtitle:before {
  content: "·";
  display: inline-block;
  font-size: 40px;
  vertical-align: middle;
  margin: 0px 10px;
  line-height: 20px;
}
.secondary-toolbar.booking-breadcrumb,
vex-breadcrumbs.booking-breadcrumb {
  --secondary-toolbar-height: 68px;
}
.secondary-toolbar.booking-breadcrumb .title,
vex-breadcrumbs.booking-breadcrumb .title {
  font-family: var(--font-dinamit);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--color-dark1);
  margin-left: 0.5rem;
}
.secondary-toolbar.booking-breadcrumb .subtitle,
vex-breadcrumbs.booking-breadcrumb .subtitle {
  font-family: var(--font-dinamit);
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6rem;
  color: var(--color-dark1);
  margin-left: 1.5rem;
  position: relative;
}
.secondary-toolbar.booking-breadcrumb .subtitle:before,
vex-breadcrumbs.booking-breadcrumb .subtitle:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-dark1);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -0.75rem;
  margin: 0;
  transform: translate(-50%, -50%);
}
.secondary-toolbar.booking-breadcrumb i.icon,
vex-breadcrumbs.booking-breadcrumb i.icon {
  width: auto;
  height: auto;
}
.secondary-toolbar.booking-breadcrumb i.icon img,
vex-breadcrumbs.booking-breadcrumb i.icon img {
  width: 2rem;
  height: auto;
}
.secondary-toolbar.booking-breadcrumb,
vex-breadcrumbs.booking-breadcrumb {
  --secondary-toolbar-height: 68px;
}
.secondary-toolbar.booking-breadcrumb .title,
vex-breadcrumbs.booking-breadcrumb .title {
  font-family: var(--font-dinamit);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--color-dark1);
  margin-left: 0.5rem;
}
.secondary-toolbar.booking-breadcrumb .subtitle,
vex-breadcrumbs.booking-breadcrumb .subtitle {
  font-family: var(--font-dinamit);
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6rem;
  color: var(--color-dark1);
  margin-left: 1.5rem;
  position: relative;
}
.secondary-toolbar.booking-breadcrumb .subtitle:before,
vex-breadcrumbs.booking-breadcrumb .subtitle:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-dark1);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -0.75rem;
  margin: 0;
  transform: translate(-50%, -50%);
}
.secondary-toolbar.booking-breadcrumb i.icon,
vex-breadcrumbs.booking-breadcrumb i.icon {
  width: auto;
  height: auto;
}
.secondary-toolbar.booking-breadcrumb i.icon img,
vex-breadcrumbs.booking-breadcrumb i.icon img {
  width: 2rem;
  height: auto;
}

vex-breadcrumbs {
  align-items: center;
}

.btn-edit {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

.btn-close {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 1280px) {
  div[style*="65%"] {
    width: 100% !important;
  }
}

@media (max-width: 1280px) {
  mat-card[style*="33%"] {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}

@tailwind components;
@tailwind utilities;
.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply shadow bg-foreground;
  background: var(--is-light-theme, #ffffff) var(--is-dark-theme, var(--color-grey5)) !important;
  border-radius: 10px;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.bg-pattern {
  background: linear-gradient(135deg, var(--is-light-theme, rgb(245, 245, 248)) var(--is-dark-theme, black) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), linear-gradient(225deg, var(--is-light-theme, rgb(245, 245, 248)) var(--is-dark-theme, black) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--is-light-theme, rgb(245, 245, 248)) var(--is-dark-theme, black);
  background-size: 64px 128px;
}

.trans-ease-out {
  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}