.vex-layout-poseidon {
  $sidenav-background: #0a1929;
  --sidenav-item-min-height: 40px;

  --sidenav-item-background-hover: rgba(var(--color-primary-sidenav), 0.1);
  --sidenav-item-background-active: rgba(var(--color-primary-sidenav), 0.2);
  --sidenav-item-dropdown-background: var(--sidenav-background);
  --sidenav-item-dropdown-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-dropdown-background-active: rgba(var(--color-primary), 0.2);

  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding-12);

  .vex-sidenav-item {
    @apply mx-3 mb-1 rounded;
  }

  --sidenav-item-color: var(--is-light-theme, rgba(0, 0, 0, 1))
    var(--is-dark-theme, rgba(255, 255, 255, 0.8));
  --sidenav-item-color-hover: var(--is-light-theme, rgba(0, 0, 0, 1))
    var(--is-dark-theme, #ffffff);
  --sidenav-item-dropdown-color-hover: rgba(0, 0, 0, 1);
  --sidenav-item-dropdown-color-active: rgba(0, 0, 0, 1);
}
