@use "@angular/material" as mat;
// Typography

/*
  InterDynamicTracking takes the font size in points
  or pixels and returns the compensating tracking in EM.
  function InterDynamicTracking(fontSize) {
    const a = -0.0223
    const b = 0.185
    const c = -0.1745;
    // tracking = a + b * e ^ (c * fontSize)
    return a + b * Math.pow(Math.E, c * fontSize)
  }
*/
.ql-editor {
  font-family: DM Sans, serif;
}
.ql-toolbar.ql-snow {
  font-family: DM Sans, serif !important;
}

.ql-container {
  font-family: DM Sans, serif !important;
}

.display-4 {
  @include mat.typography-level($config, headline-1);
}

.display-3 {
  @include mat.typography-level($config, headline-2);
}

.display-2 {
  @include mat.typography-level($config, headline-3);
}

.display-1,
h1 {
  @include mat.typography-level($config, headline-4);
}

.headline,
h2 {
  @include mat.typography-level($config, headline-5);
}

.title,
h3,
.subtitle {
  @include mat.typography-level($config, headline-6);
}

.subheading-2,
h4 {
  @include mat.typography-level($config, subtitle-1);
}

.subheading-1,
h5 {
  @include mat.typography-level($config, subtitle-2);
}

.body-2,
h6 {
  @include mat.typography-level($config, body-2);
}

.body-1,
p {
  @include mat.typography-level($config, body-1);
}

.caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--font);
  letter-spacing: 0;
  color: var(--is-light-theme, rgba(0, 0, 0, 0.38))
    var(--is-dark-theme, #73777a) !important;
}

.text-xs {
  letter-spacing: 0;
}

.text-sm {
  letter-spacing: -0.009em;
}

.text-base {
  letter-spacing: -0.011em;
}

.text-lg {
  letter-spacing: -0.014em;
}

.text-2xl {
  letter-spacing: -0.019em;
}

.text-3xl {
  letter-spacing: -0.021em;
}
