:root {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: var(--is-light-theme, #ffffff)
      var(--is-dark-theme, #222222);
    @apply rounded-lg;
  }

  .mat-mdc-dialog-title {
    @apply pt-3;
  }

  /** Reset default height placeholder */
  .mdc-dialog__title::before {
    content: none;
    height: auto;
  }

  .customBookingDialog {
    .mat-mdc-dialog-container .mdc-dialog__content {
      padding: 0;
    }
  }
}
