html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11", "salt", "calt", "case";
  @apply font-sans leading-normal antialiased;
}

body {
  height: 100%;
  letter-spacing: -0.006em;
  @apply text-sm text-black;
}

div {
  box-sizing: border-box;
}

input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  border-left: 3px solid theme("backgroundColor.primary");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;

  > * {
    margin: 0;
  }
}

.btn-primary-accent {
  position: relative;
  display: inline-block;
  min-width: 200px;
  color: rgba(255, 255, 255, 1);
  background-color: var(--color-accent-rgb);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 00.2);
}

.vex-toolbar {
  box-shadow: none !important;
}

.secondary-toolbar,
vex-breadcrumbs {
  /*box-shadow: none!important;
  --tw-shadow: none!important;*/

  i {
    &.icon {
      width: 48px;
      height: 48px;
      display: inline-flex;
      align-items: center;

      @media (max-width: 480px) {
        width: 38px;
        height: 38px;
      }

      img {
        width: 36px;
        height: 36px;
        margin-top: -4px;

        @media (max-width: 480px) {
          margin-top: -2px;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;

    color: var(--color-dark1);

    width: auto !important;
    float: none !important;

    /*&:before {
      content: "\2022";
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
      margin-right: 8px;
    }*/

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  .subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--color-dark1);
    @media (max-width: 480px) {
      font-size: 12px;
    }

    &:before {
      content: "·";
      display: inline-block;
      font-size: 40px;
      vertical-align: middle;
      margin: 0px 10px;
      line-height: 20px;
    }
  }

  &.booking-breadcrumb {
    --secondary-toolbar-height: 68px;
    .title {
      font-family: var(--font-dinamit);
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.6rem;
      color: var(--color-dark1);
      margin-left: 0.5rem;
    }

    .subtitle {
      font-family: var(--font-dinamit);
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: var(--color-dark1);
      margin-left: 1.5rem;
      position: relative;
      &:before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        background-color: var(--color-dark1);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -0.75rem;
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }

    i {
      &.icon {
        width: auto;
        height: auto;
        img {
          width: 2rem;
          height: auto;
        }
      }
    }
  }

  &.booking-breadcrumb {
    --secondary-toolbar-height: 68px;
    .title {
      font-family: var(--font-dinamit);
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.6rem;
      color: var(--color-dark1);
      margin-left: 0.5rem;
    }

    .subtitle {
      font-family: var(--font-dinamit);
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: var(--color-dark1);
      margin-left: 1.5rem;
      position: relative;
      &:before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        background-color: var(--color-dark1);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -0.75rem;
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }

    i {
      &.icon {
        width: auto;
        height: auto;
        img {
          width: 2rem;
          height: auto;
        }
      }
    }
  }
}
vex-breadcrumbs {
  align-items: center;
}

.btn-edit {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

.btn-close {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  margin-left: 10px;
}

div[style*="65%"] {
  @media (max-width: 1280px) {
    width: 100% !important;
  }
}

mat-card[style*="33%"] {
  @media (max-width: 1280px) {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}
