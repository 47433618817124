@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import "~quill/dist/quill.snow.css";
// Vex Core
@import "./@vex/styles/core";
@import "../node_modules/angular-calendar/scss/angular-calendar.scss";

@font-face {
  font-family: "Dinamit";
  font-weight: 100; /* Thin */
  src: url("./assets/fonts/Dinamit/Dinamit Thin.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 200; /* ExtraLight */
  src: url("./assets/fonts/Dinamit/Dinamit ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 300; /* Light */
  src: url("./assets/fonts/Dinamit/Dinamit Light.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 400; /* Regular */
  src: url("./assets/fonts/Dinamit/Dinamit Regular.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 500; /* Medium */
  src: url("./assets/fonts/Dinamit/Dinamit Medium.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 600; /* SemiBold */
  src: url("./assets/fonts/Dinamit/Dinamit SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 700; /* Bold */
  src: url("./assets/fonts/Dinamit/Dinamit Bold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 800; /* ExtraBold */
  src: url("./assets/fonts/Dinamit/Dinamit Bold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 900; /* Black */
  src: url("./assets/fonts/Dinamit/Dinamit Bold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 100; /* Thin */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Thin Italic.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 200; /* ExtraLight */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit ExtraLight Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 300; /* Light */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Light Italic.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 400; /* Regular */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Regular Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 500; /* Medium */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Medium Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 600; /* SemiBold */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit SemiBold Italic.otf")
    format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 700; /* Bold */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Bold Italic.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 800; /* ExtraBold */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Bold Italic.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit";
  font-weight: 900; /* Black */
  font-style: italic;
  src: url("./assets/fonts/Dinamit/Dinamit Bold Italic.otf") format("opentype");
}

/*
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.otf') format('opentype');
  unicode-range: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F";
}*/

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXp-p7K4KLjztg.woff2")
    format("woff2");
  unicode-range: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F";
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXp-p7K4KLjztg.woff2")
    format("woff2");
  unicode-range: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116";
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw2aXp-p7K4KLjztg.woff2")
    format("woff2");
  unicode-range: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB";
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aXp-p7K4KLjztg.woff2")
    format("woff2");
  unicode-range: "U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF";
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXp-p7K4KLg.woff2")
    format("woff2");
  unicode-range: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD";
}

@font-face {
  font-family: "DM Sans";
  font-weight: 100; /* Thin */
  src: url("./assets/fonts/DM_Sans/DMSans-Thin.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 200; /* ExtraLight */
  src: url("./assets/fonts/DM_Sans/DMSans-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 300; /* Light */
  src: url("./assets/fonts/DM_Sans/DMSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 400; /* Regular */
  src: url("./assets/fonts/DM_Sans/DMSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 500; /* Medium */
  src: url("./assets/fonts/DM_Sans/DMSans-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 600; /* SemiBold */
  src: url("./assets/fonts/DM_Sans/DMSans-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 700; /* Bold */
  src: url("./assets/fonts/DM_Sans/DMSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 800; /* ExtraBold */
  src: url("./assets/fonts/DM_Sans/DMSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 900; /* Black */
  src: url("./assets/fonts/DM_Sans/DMSans-Black.ttf") format("opentype");
}

vex-calendar {
  .calendar-bg {
    background: url("./assets/img/demo/landscape.jpg") no-repeat center;
    background-size: cover;
  }
}
body {
  color: var(--is-light-theme, #121212) var(--is-dark-theme, #dee6ea);
  font-family: "DM Sans", sans-serif; /* Usa la fuente Dinamit como fuente predeterminada */
}

/**
 * angular-calendar overwrites
 */
.cal-month-view {
  background: var(--vex-background-card);

  .cal-days {
    @apply border-divider;

    .cal-cell-row {
      @apply border-divider;
    }
  }

  .cal-header .cal-cell {
    @apply py-3 text-sm font-medium;
  }

  .cal-cell-row {
    &:hover {
      background: var(--vex-background-hover);
    }

    .cal-cell {
      &:hover {
        background: var(--vex-background-hover);
      }

      &.cal-open {
        background: var(--vex-background-hover);
        @apply shadow-lg;
      }
    }
  }

  .cal-day-cell {
    min-height: 150px;

    &.cal-today {
      background: var(--vex-background-app-bar);
    }

    &:not(:last-child) {
      @apply border-divider;
    }
  }

  .cal-open-day-events {
    //@apply bg-primary-600 border-b;

    > div {
      align-content: center;
      align-items: center;
      background: var(--vex-background-card);
      color: var(--vex-text-dark);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @apply shadow px-6;

      & + div {
        @apply mt-3;
      }

      mwl-calendar-event-title {
        display: flex;
        flex: 1;
        flex-direction: row;

        .cal-event-title {
          //@apply flex-1 text-default text-sm p-3;
        }
      }

      .cal-event-action {
        & + .cal-event-action {
          @apply ml-3;
        }
      }
    }
  }
}

.cal-week-view,
.cal-day-view {
  background: var(--vex-background-card);

  .cal-header {
    @apply text-sm font-medium;

    b {
      font-weight: 500;
    }

    &.cal-today {
      background: var(--vex-background-app-bar);
    }
  }

  .cal-day-headers .cal-header:hover,
  .cal-day-headers .cal-drag-over {
    background-color: var(--vex-background-hover);
  }

  .cal-hour {
    background: var(--vex-background-card);

    &:nth-child(odd) {
      background: var(--vex-background-card);
    }
  }

  .cal-hour-odd {
    background: var(--vex-background-app-bar);
  }

  .cal-hour-segment {
    &:hover {
      background: var(--vex-background-hover);
    }
  }

  .cal-time-events {
    .cal-day-columns {
      .cal-hour-segment {
        &:hover {
          background: var(--vex-background-hover);
        }
      }
    }
  }

  .cal-event {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    mwl-calendar-event-actions {
      order: 2;
    }

    mwl-calendar-event-title {
      display: block;
      flex: 1;
      order: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .cal-event-title {
        outline: none;
      }
    }

    .cal-event-actions {
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .cal-event-action {
        height: auto;

        .icon {
          font-size: 18px;
          @apply p-1;
        }
      }
    }
  }
}

.cal-event-title {
  //@apply text-default no-underline text-sm;
}
.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow: var(--color-dark1);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px
      rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}

.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Roboto, sans-serif;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: 0.0178571429em;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}

.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-body2-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.875rem;
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: var(--color-dark2);
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  --color-primary-red: #e91e63;
  --color-black: #121212;
  --color-white: #fff;
  --color-green: #31c044;
  --color-yellow: #ffcf25;
  --color-red: #e70f0f;
  --color-blue: #006fcb;

  --font-dinamit: "Dinamit", sans-serif;
  --font-dm: "DM Sans", sans-serif;
  --font-montserrat: "Montserrat", sans-serif;
  --mdc-checkbox-selected-checkmark-color: var(--color-white);
  --is-light-theme: initial;
  --is-dark-theme: ;
}

.dark-mode {
  --is-light-theme: !important;
  --is-dark-theme: initial !important;
}

body {
  --color-dark1: #222222;
  --color-dark2: #2f3844;
  --color-dark3: #61656d;
  --color-dark4: #1b2d44;
  --color-dark5: #122742;
  --color-grey1: #8b9099;
  --color-grey2: #c7d0d3;
  --color-grey3: #dee6ea;
  --color-grey4: #eff2f5;
  --color-grey5: #f8f9fb;
}

body.dark-mode {
  --color-dark1: #f8f9fb;
  --color-dark2: #eff2f5;
  --color-dark3: #dee6ea;
  --color-dark4: #c7d0d3;
  --color-dark5: #8b9099;
  --color-grey1: #122742;
  --color-grey2: #1b2d44;
  --color-grey3: #61656d;
  --color-grey4: #2f3844;
  --color-grey5: #222222;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  background-color: white !important;
  border-radius: 3px;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  margin: auto;
}

.icon10 {
  height: 10px;
  width: 10px;
  font-size: 10px;
  min-width: 10px;
}
.icon16 {
  width: 16px;
  height: 16px;
  font-size: 16px;
  min-width: 16px;
}
.icon20 {
  width: 20px;
  height: 20px;
  font-size: 20px;
  min-width: 20px;
}
.icon24 {
  width: 24px;
  height: 24px;
  font-size: 24px;
  min-width: 24px;
}
.icon36 {
  width: 36px;
  height: 36px;
  font-size: 36px;
  min-width: 36px;
}
.icon40 {
  width: 40px;
  height: 40px;
  font-size: 40px;
  min-width: 40px;
}
.mat-mdc-card {
  height: fit-content;
}

.angular-editor-textarea {
  margin: 0 !important;
  border-top: 0 !important;
}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button,
.ae-font .ae-picker-options {
  border: none !important;
  background: none !important;
}

.angular-editor-toolbar,
.ae-font .ae-picker-label {
  background-color: var(--is-light-theme, #ffffff) var(--is-dark-theme, #1d1d1d) !important;
}

.mat-mdc-outlined-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mdc-button__ripple {
  background-color: var(--color-grey5);
  border: 1px solidvar(--color-dark1);
}

.mat-mdc-table,
.mat-mdc-paginator {
  background-color: var(--color-grey5) !important;
}
.mat-mdc-card {
  background-color: var(--is-light-theme, #ffffff)
    var(--is-dark-theme, var(--color-grey5)) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled + .mdc-label {
  color: var(--color-dark1);
}
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  color: var(--is-light-theme, #121212) var(--is-dark-theme, #dee6ea);
}

.mat-sort-header-content,
.mat-mdc-row,
.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator .mat-mdc-select-value,
.mat-mdc-paginator,
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab,
.mat-mdc-slide-toggle .mdc-switch--disabled + label,
.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
.mat-mdc-card-subtitle ~ .mat-mdc-card-title,
.mat-mdc-card-title ~ .mat-mdc-card-subtitle,
.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title,
.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-subtitle,
.mat-mdc-card-title-group .mat-mdc-card-title,
.mat-mdc-card-title-group .mat-mdc-card-subtitle,
.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-form-field
  .mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above,
.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label,
.mat-mdc-raised-button[disabled][disabled],
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
:root .mat-mdc-menu-item .mat-icon,
.mdc-form-field > label,
.mat-mdc-card-content:last-child,
.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  color: var(--is-light-theme, #121212) var(--is-dark-theme, #dee6ea) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #dee6ea !important;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper,
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  background: var(--is-light-theme, #ffffff) var(--is-dark-theme, #222222);
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper,
.mat-stepper-vertical,
.mat-stepper-horizontal,
.simplebar-offset {
  background: var(--is-light-theme, #ffffff) var(--is-dark-theme, #222222) !important;
}
input::placeholder {
  color: var(--is-light-theme, #acacac) var(--is-dark-theme, #ebebeb) !important;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: white;
}
.mat-mdc-menu-panel {
  background: none !important;
}
button[color="primary"] {
  span {
    color: white !important;
  }
}

mat-calendar-header,
button[color="accent"] {
  span {
    color: black !important;
  }
}
