.mat-mdc-checkbox {
  &.mat-primary {
    --mdc-checkbox-selected-checkmark-color: var(--color-white);
  }

  .mdc-checkbox__background {
    border-color: var(--color-dark1) !important;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
