:root {
  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &:not(:disabled):not(.mat-unthemed) {
      @apply text-primary-contrast;
    }
  }

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply rounded-button min-w-[6rem];
  }

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 10px 12px;
    height: 38px;
    font-family: var(--font-dm);
    letter-spacing: 0px;
    color: var(--color-primary-red) !important;
    background-color: transparent;
    &:hover {
      background-color: #ba194e;
      color: var(--color-white) !important;
    }

    &:disabled {
      color: var(--color-grey2) !important;
    }
  }

  .mat-mdc-unelevated-button {
    background-color: var(--color-primary-red) !important;
    color: var(--color-white) !important;
    &:hover {
      background-color: #ba194e !important;
    }

    &:disabled {
      background-color: var(--color-grey2) !important;
      color: var(--color-white) !important;
    }
  }

  .mat-mdc-outlined-button {
    background-color: var(--color-white);
    color: var(--color-dark1) !important;
    border: 1px solid var(--color-dark1) !important;
    &:hover {
      background-color: var(--color-dark1);
      color: var(--color-white) !important;
    }

    &:disabled {
      color: var(--color-grey2) !important;
      border: 1px solid var(--color-grey2) !important;
    }
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-mdc-icon-button {
    .mat-icon {
      @apply inline-flex items-center justify-center;
    }
  }
}
